.sidebar {
  border: 1px solid #eee;
  background-color: #fff;
  flex: 1;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.SBheader {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.brand-name {
  font-size: 2rem;
  letter-spacing: 0.6px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-right: 10px;
}

.member-ship {
  background-color: #eee;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;
  letter-spacing: 0.7px;
}

.menu-option {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 10px 0px;
  
}

.menu-option ul {
  margin: 0;
  padding: 0;
}

.menu-item-group {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
}

.menu-item-group-active {
  background-color: aliceblue;
  border-radius: 10px;
}

.menu-item-group:hover {
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.1s ease-in;
}

.icon-style {
  font-size: 18px;
  color: gray;
  flex: 1;
}

.menu-item-group span {
  font-size: 16px;
  color: gray;
  flex: 3;
  letter-spacing: 0.8px;
}

.SBfooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom:3%;   
}

.footer-icon-style {
  font-size: 20px;
  background-color: lightgray;
  padding: 8px 12px;
  margin-right: 15px;
  border-radius: 10px;
}

.footer-icon-style:hover {
  background-color: rgb(165, 162, 162);
  color: #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
