.org_content-container {
  width: 100%;
  height: 98%;
  border-radius: 20px;
  margin: 15px auto;
  padding: 10px 25px;
  background-color: #fff;
}

.org-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.org-header span {
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.org-inp-container {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  padding: 5px;
}

.org-inp-container input {
  padding: 13px 10px;
  font-size: 14px;
  width: 30%;
  margin-right: 15px;
  border-radius: 5px;
  background-color: #eee;
  border: none;
  text-transform: uppercase;
  outline: 1px solid #fff;
}

.org-inp-container input:focus {
  outline: 1px solid gray;
}

.org-inp-container button {
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  border: none;
  padding: 2px 15px;
  background-color: rgba(48, 125, 212, 0.925);
}

.org-inp-container button i {
  margin-right: 10px;
}

.org-inp-container button:hover {
  cursor: pointer;
}

.org-table-container {
  width: 100%;
  margin-top: 8px;
  height: 93%;
  border-radius: 20px;
  position: relative;
  background-color: #fff;
}

.action-cell {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-around; */
  width: 100%;
  padding: 5px;
}

.org-admin-role {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 15px;
}

.action-cell i {
  font-size: 16px;
  margin-right: 20px;
  color: goldenrod;
}

.action-cell .trash-icon {
  color: rgb(230, 31, 31);
}

.action-cell i:hover {
  cursor: pointer;
}

.org-inp-container .org-add-btn {
  padding: 10px 15px;
}

.org-inp-container .emp-hirarchy {
  display: flex;
  border: 1px solid rgb(60, 91, 231);
  border-radius: 10px;
  margin-right: 15px;
  width: 40%;
}

.org-inp-container .emp-hirarchy .left,
.org-inp-container .emp-hirarchy .right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px;
}

.org-inp-container .emp-hirarchy .right {
  background-color: rgb(192, 238, 192);
  border-radius: 0px 10px 10px 0px;
}

.org-inp-container .emp-hirarchy .right:hover {
  cursor: pointer;
  background-color: #eee;
}

.org-inp-container .emp-hirarchy .left span {
  font-size: 15px;
  color: grey;
}

.org-inp-container .emp-hirarchy .right span {
  font-size: 15px;
  color: green;
}

/* .org-inp-container .emp-hirarchy span {
  font-size: 15px;
  padding: 5px 20px;
  color: grey;  
  flex: 1;
}

.org-inp-container .emp-hirarchy span:nth-child(2) {
  color: green;
  background-color: rgb(66, 248, 187);
  flex: 0.25;
} */

.org-inp-container .emp-hirarchy span:hover {
  cursor: pointer;
}

.grid-header {
  font-size: 15px;
  background-color: #eee;
}

.org-new-emp-page {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
}
.org-new-emp-form {
  border: 1px solid #eee;
  padding: 50px 30px;
  display: flex;
  width: 350px;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}

.org-new-emp-form i {
  position: absolute;
  background-color: #fff;
  padding: 5px 8px;
  border-radius: 50%;
  top: 5px;
  right: 10px;
  font-size: 18px;
  border: 1px solid grey;
}

.org-new-emp-form i:hover {
  cursor: pointer;
}

.org-new-emp-form label {
  font-size: 20px;
  letter-spacing: 0.7px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 1, 0, 0.3);
}

.org-new-emp-form input {
  padding: 12px;
  font-size: 15px;
  background-color: #eee;
  outline: none;
  border: none;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.org-new-emp-form select {
  background-color: #eee;
  padding: 15px;
  font-size: 15px;
  margin-bottom: 20px;
  border: none;
  outline: none;
}

.org-new-emp-form .submit-btn {
  width: 130px;
  font-size: 16px;
  display: inline-block;
  background-color: rgba(48, 125, 212, 0.925);
  color: #fff;
  border: none;
  padding: 10px;
  margin: -15px auto;
  border-radius: 10px;
}

.org-new-emp-form .submit-btn:hover {
  cursor: pointer;
}

.org-new-data-page {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.org-new-form {
  border: 1px solid #eee;
  padding: 30px 20px;
  border-radius: 15px;
  display: flex;
  margin: 10% auto;
  max-width: 300px;
  flex-direction: column;
  background-color: #eee;
  position: relative;
}

.org-new-form i {
  position: absolute;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 50%;
  top: 10px;
  right: 20px;
  font-size: 22px;
  border: 1px solid grey;
}

.org-new-form i:hover {
  cursor: pointer;
  scale: 1.1;
  color: rgb(206, 74, 74);
  transition: all 0.1s ease-in-out;
  border: 1px solid red;
}

.org-new-form label {
  font-size: 20px;
  letter-spacing: 0.7px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 1, 0, 0.3);
}

.org-new-form input {
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  outline: none;
  border: 1px solid gray;
}
/* .org-new-form .submit-btn {
  width: 130px;
  display: inline-block;
  background-color: rgba(48, 125, 212, 0.925);
  color: #fff;
  border: none;
  margin: 10px 5px 5px auto;
} */

.org-new-form .submit-btn {
  width: 130px;
  font-size: 16px;
  display: inline-block;
  background-color: rgba(48, 125, 212, 0.925);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 5px 5px auto;
}

.org-new-form .submit-btn:nth-child(2) {
  background-color: rgb(236, 23, 23);
}

.org-new-form .submit-btn:hover {
  cursor: pointer;
}

.org_component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  /* padding: 15px 30px; */
  padding: 10px 5px;
  border-radius: 10px;
  margin-right: 20px;
  position: relative;
}

.org_component .org-name-icon {
  background-color: #cce5ff;
  width: 45px;
  height: 40px;
  font-size: 18px;
  color: dodgerblue;
  /* color: rgb(241, 103, 103); */
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ord-emp-det {
  display: flex;
  flex-direction: column;
}

.ord-emp-det .emp-mail {
  color: grey;
  font-size: 13px;
  margin-top: 2px;
}

.org-fe-btn-group button {
  margin-right: 10px;
}

.upload-page-container {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.upload-page {
  border: 5px solid dodgerblue;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  padding-bottom: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
}

.upload-page .header-close-icon {
  position: absolute;
  padding: 5px 8px;
  color: dodgerblue;
  top: 2px;
  right: 5px;
  font-size: 20px;
}

.upload-page .header-close-icon:hover {
  cursor: pointer;
  color: rgb(51, 119, 197);
}

.upload-page .icons {
  margin-right: 10px;
}

.upload-header {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
}

.upload-header h2 {
  color: dodgerblue;
  font-size: 26px;
}

.upload-page button {
  font-size: 18px;
  color: #fff;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  margin-right: 25px;
  background-color: rgba(48, 125, 212, 0.925);
}

.upload-page button:hover {
  cursor: pointer;
}
