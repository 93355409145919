.ph_content-container {
  border: 1px solid #eee;
  width: 100%;
  height: 98%;
  background-color: #fff;
  border-radius: 20px;
  margin: 15px auto;
}

.ph_content-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  align-items: center;
}

.ph_header_input {
  width: 50%;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}

.ph_content-header span {
  font-size: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 550;
}

.ph_content-header input {
  padding: 10px;
  width: 25%;
  font-size: 14px;
  margin-right: 15px;
  border-radius: 5px;
  background-color: #eee;
  border: none;
  outline: 1px solid #fff;
}

.ph_content-header input:focus {
  outline: 1px solid gray;
}

.ph_header_input button {
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  border: none;
  padding: 2px 15px;
  background-color: rgba(48, 125, 212, 0.925);
}

.ph_header_input button:hover {
  cursor: pointer;
}

.ph_content_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 93%;
  border-radius: 0px 0px 10px 10px;
}

.ph_content_view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -50px;
  border-radius: 0px 0px 10px 10px;
}

.ph_content_view img {
  width: 150px;
  margin-bottom: 35px;
}

.ph_content_view span {
  font-size: 22px;
  font-weight: 550;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-bottom: 35px;
}

.ph_content_view i {
  margin-right: 10px;
}

.ph_content_view button {
  font-size: 15px;
  color: #fff;
  border-radius: 8px;
  border: none;
  padding: 15px;
  letter-spacing: 0.7px;
  background-color: rgba(48, 125, 212, 0.925);
}

.ph_content_view button:hover {
  cursor: pointer;
}

.ph_table_container {
  width: 100%;
  height: 88%;
  border-radius: 20px;
  position: relative;
  background-color: #fff;
  padding: 10px 20px;
}

.action-cell select {
  width: 180px;
  padding: 10px;
  font-size: 15px;
  outline: none;
  color: #1a1818;
  border: 1px solid #eee;
}

/* Shift*/

.sh_content-container {
  border: 1px solid #eee;
  width: 100%;
  height: 98%;
  border-radius: 20px;
  margin: 15px auto;
}

.sh_content-header {
  width: 100%;
  height: 20%;
  border-radius: 20px;
  padding: 20px;
  background-color: #fff;
}

.sh_content_header_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sh_content_header_content span {
  font-size: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 550;
}

.sh_content_header_content button {
  color: #fff;
  padding: 10px 15px;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 15px;
  background-color: rgba(48, 125, 212, 0.925);
}

.sh_content_header_content button i {
  margin-right: 10px;
}

.sh_content_header_content button:hover {
  cursor: pointer;
}

.sh_shift_container {
  margin-top: 20px;
  padding: 20px 0px;
  border-top: 1px solid #eee;
  display: flex;
}

.sh_shift_component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 15px 30px;
  border-radius: 10px;
  background-color: #cde8ca;
  margin-right: 20px;
  position: relative;
}

.sh_shift_component:hover {
  cursor: move;
}

.sh_shift_component:hover .close-icon {
  display: block;
}

.sh_shift_component .close-icon {
  background-color: transparent;
  color: grey;
  position: absolute;
  padding: 0px;
  top: 5px;
  right: -5px;
  display: none;
}

.sh_shift_component .close-icon:hover {
  color: red;
  cursor: pointer;
}

.sh_shift_component i,
.sh_shift_component .roaster-icon {
  background-color: #fff;
  padding: 10px;
  font-size: 18px;
  color: rgb(241, 103, 103);
  border-radius: 10px;
  margin-right: 15px;
}

.sh_shift {
  display: flex;
  flex-direction: column;
}

.sh_shift .shiftName {
  color: gray;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.sh_shift .shiftTime {
  color: #000;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.sh_content_view {
  width: 100%;
  margin-top: 15px;
  height: 80%;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  background-color: #fff;
}

.new-shift-page1 {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.new-shift-form1 {
  border: 1px solid #eee;
  padding: 30px 20px;
  border-radius: 15px;
  display: flex;
  margin: 10% auto;
  max-width: 500px;
  flex-direction: column;
  background-color: #eee;
  position: relative;
}

.new-shift-form1 i {
  position: absolute;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 50%;
  top: 10px;
  right: 20px;
  font-size: 22px;
  border: 1px solid grey;
}

.new-shift-form1 i:hover {
  cursor: pointer;
  scale: 1.1;
  color: rgb(206, 74, 74);
  transition: all 0.1s ease-in-out;
  border: 1px solid red;
}

.new-shift-form1 label {
  font-size: 18px;
  letter-spacing: 0.7px;
}
.new-shift-form1 input {
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  outline: none;
  border: 1px solid gray;
}
/* .new-shift-form1 .submit-btn {
  width: 130px;
  display: inline-block;
  background-color: rgba(48, 125, 212, 0.925);
  color: #fff;
  border: none;
  margin: 10px 5px 5px auto;
} */

.new-shift-form1 .submit-btn {
  width: 130px;
  font-size: 16px;
  display: inline-block;
  background-color: rgba(48, 125, 212, 0.925);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 5px 5px auto;
}

.new-shift-form1 .submit-btn:nth-child(2) {
  background-color: rgb(236, 23, 23);
}

.new-shift-form1 .submit-btn:hover {
  cursor: pointer;
}

/* Attendance status */

.leave-type-card {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #e6f0e4;
  margin-right: 20px;
  position: relative;
  width: 130px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.leave-type-card .leave-short-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.leave-type-card .leave-type {
  font-size: 15px;
  color: rgb(102, 95, 95);
  letter-spacing: 0.3px;
}

.leave-type-card i {
  color: rgb(155, 150, 150);
  font-size: 13px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.leave-type-card i:hover {
  cursor: pointer;
}
