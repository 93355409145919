.content-container {
  border: 1px solid #eee;
  width: 100%;
  height: 98%;
  background-color: #fff;
  border-radius: 20px;
  margin: 15px auto;
}

.content-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  border-radius: 20px 20px 0px 0px;
}

.timeline-btn {
  background-color: rgba(27, 111, 207, 0.925);
  color: #fff;
  padding: 10px 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
}

.timeline-btn:hover {
  cursor: pointer;
}

.timeline-search-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-search-container select {
  font-size: 18px;
  padding: 10px;
  width: 35%;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #f3f1f1;
  border: 1px solid #000;
}

.timeline-date-input {
  font-size: 18px;
  padding: 8px 10px;
  width: 25%;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #f3f1f1;
  border: 1px solid #000;
}

.timeline-date-input:focus,
.timeline-search-container select:focus {
  outline-color: dodgerblue;
}

.option-icon {
  font-size: 18px;
  margin-right: 8px;
}

.timeline-map-view {
  background-image: url("../../../images/timeline-map.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 93%;
  border-radius: 0px 0px 10px 10px;
}
