.main-content {
  flex: 11;
  background-color: #eee;
  padding: 5px 20px;
}

.content-icons-group {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin: 1px 5px;
}

.content-icon {
  background-color: #fff;
  padding: 10px 12px;
  font-size: 16px;
  border-radius: 50%;
  margin-right: 15px;
}

.content-icon:hover {
  cursor: pointer;
  color: rgba(48, 125, 212, 0.925);
}

.content-menu-group {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(173, 171, 171, 0.5);
}

.content-menu-btn {
  margin-right: 10px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 16px;
  letter-spacing: 0.7px;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.content-menu-btn-active {
  background-color: rgba(48, 125, 212, 0.925);
  color: #fff;
  border-radius: 12px 12px 0px 0px;
}

.content-menu-btn:hover {
  cursor: pointer;
}

.sub-menu-icon {
  margin-right: 10px;
}

.child-container {
  width: 100%;
  height: 90vh;
  padding: 0px 10px;
  margin-top: -0.5%;
}
